export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        categoryfeedbacks :'', 
        categoryfeedback :'', 
    },
    
    getters : {
        categoryfeedbacks: (state )=> state.categoryfeedbacks,
        categoryfeedback: (state )=> state.categoryfeedback,
    },


    mutations : { 
        setcategoryfeedbacks :(state,categoryfeedbacks ) => (state.categoryfeedbacks = categoryfeedbacks), 
        setcategoryfeedback :(state,categoryfeedback ) => (state.categoryfeedback = categoryfeedback), 
    },

    actions : {

        async create_categoryfeedback( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_categoryfeedback')
            return new Promise((resolve, reject) => {
            axios.post("/admin/categoryfeedbacks/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcategoryfeedbacks', response.data.res)
                toast.success("Feedback Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_categoryfeedbacks( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_categoryfeedbacks')
            return new Promise((resolve, reject) => {
            axios.post("/admin/categoryfeedbacks?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcategoryfeedbacks', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_categoryfeedback( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_categoryfeedback' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/categoryfeedbacks/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcategoryfeedback', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_categoryfeedback( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_categoryfeedback' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/categoryfeedbacks/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcategoryfeedbacks', response.data.res)
                toast.success("Feedback Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_categoryfeedback_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_categoryfeedback_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/admin/categoryfeedbacks/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setcategoryfeedbacks', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Feedback Activated Successfully")
                        } else {
                            toast.success("Feedback Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_categoryfeedback( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_categoryfeedback' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/categoryfeedbacks/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcategoryfeedbacks', response.data.res)
                toast.success("Feedback Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}