export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        systemupdates :'', 
        systemupdate :'', 
    },
    
    getters : {
        systemupdates: (state )=> state.systemupdates,
        systemupdate: (state )=> state.systemupdate,
    },


    mutations : { 
        setsystemupdates :(state,systemupdates ) => (state.systemupdates = systemupdates), 
        setsystemupdate :(state,systemupdate ) => (state.systemupdate = systemupdate), 
    },

    actions : {

        async create_system_update( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_system_update')
            return new Promise((resolve, reject) => {
            axios.post("/admin/settings/updates/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsystemupdates', response.data.res)
                toast.success("systemupdate Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_system_updates( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_system_updates')
            return new Promise((resolve, reject) => {
            axios.post("/admin/settings/updates?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsystemupdates', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_system_update( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_system_update' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/settings/updates/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsystemupdate', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_system_update( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_system_update' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/settings/updates/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsystemupdates', response.data.res)
                toast.success("systemupdate Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_system_update_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_system_update_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/admin/settings/updates/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setsystemupdates', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("systemupdate Activated Successfully")
                        } else {
                            toast.success("systemupdate Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_system_update( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_system_update' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/settings/updates/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setsystemupdates', response.data.res)
                toast.success("systemupdate Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}