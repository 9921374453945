export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        agents :'', 
        agent :'', 
        
    },
    
    getters : {
        agents: (state )=> state.agents,
        agent: (state )=> state.agent,
        
    },


    mutations : { 
        setagents :(state,agents ) => (state.agents = agents), 
        setagent :(state,agent ) => (state.agent = agent), 
        
    },

    actions : {

        async create_agent( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_agent')
            return new Promise((resolve, reject) => {
            axios.post("/admin/agents/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setagents', response.data.res)
                toast.success("Agent Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_agents( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_agents')
            return new Promise((resolve, reject) => {
            axios.post("/admin/agents?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setagents', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_agent( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_agent' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/agents/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setagent', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_agent_statistics( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_agent_statistics' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/agents/statistics/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstatistics', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },        


        async update_agent( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_agent' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/agents/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setagents', response.data.res)
                toast.success("Agent Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_agent_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_agent_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/admin/agents/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setagents', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Calls Unmuted Successfully")
                        } else {
                            toast.success("Calls Muted Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_agent( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_agent' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/agents/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setagents', response.data.res)
                toast.success("Agent Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}