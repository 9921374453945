export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        languages :'', 
        language :'', 
    },
    
    getters : {
        languages: (state )=> state.languages,
        language: (state )=> state.language,
    },


    mutations : { 
        setlanguages :(state,languages ) => (state.languages = languages), 
        setlanguage :(state,language ) => (state.language = language), 
    },

    actions : {

        async create_language( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_language')
            return new Promise((resolve, reject) => {
            axios.post("/admin/languages/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlanguages', response.data.res)
                toast.success("Language Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_languages( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_languages')
            return new Promise((resolve, reject) => {
            axios.post("/admin/languages?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlanguages', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_language( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_language' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/languages/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlanguage', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_lang_statistics( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_lang_statistics' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/languages/statistics/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstatistics', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        }, 

        async update_language_feeback( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_language_feeback' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/languages/update_feedback/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlanguage', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
        async update_language( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_language' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/languages/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlanguages', response.data.res)
                toast.success("Language Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_language_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_language_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/admin/languages/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setlanguages', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Language Activated Successfully")
                        } else {
                            toast.success("Language Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_language( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_language' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/languages/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlanguages', response.data.res)
                toast.success("Language Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_language_feeback_file( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_language_feeback_file' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/languages/update_language_feedback/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setlanguage', response.data.res)
                toast.success("Language Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}