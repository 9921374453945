<template>
      <router-view></router-view>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default{
    methods:{
        ...mapActions(['getuserlocation','getuseripaddress', 'get_system_settings']),

        comp_get_system_settings(){
            let data = {}
            this.get_system_settings();
        }
    },

    created(){
        this.getuseripaddress().then(()=>{
            this.getuserlocation();
        });
        this.comp_get_system_settings();
    }
}
</script>

