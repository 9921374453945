export const namespaced = true 
export default {
    state : {
        error :'',
        errors :[],
        apierror:'',
        msg :'',
        loading:'',
        pageloading:'',
        logintypetype:'',
        uploadPercentage:0,
        nav_menu:'',
        currentplayer:'',
        dashdata:'',
        statistics:'',
        systemsettings:'',
    },
    
    getters : {
        error: (state )=> state.error,
        errors: (state )=> state.errors,
        apierror: (state )=> state.apierror,
        msg: (state )=> state.msg,
        loading: (state )=> state.loading,
        pageloading: (state )=> state.pageloading,
        uploadPercentage: (state )=> state.uploadPercentage,
        nav_menu: (state )=> state.nav_menu,
        currentplayer: (state )=> state.currentplayer,
        dashdata: (state )=> state.dashdata,
        statistics: (state )=> state.statistics,
        systemsettings: (state )=> state.systemsettings,
    },

    

    mutations : { 
        setloader :(state,loading ) => (state.loading = loading), 
        setpageloading :(state,pageloading ) => (state.pageloading = pageloading), 
        seterror :(state,error ) => (state.error = error), 
        seterrors :(state,errors ) => (state.errors = errors), 
        setmsg :(state,msg ) => (state.msg = msg), 
        setapierror :(state,apierror ) => (state.apierror = apierror),
        setuploadPercentage :(state,uploadPercentage ) => (state.uploadPercentage = uploadPercentage),
        setnav_menu :(state,nav_menu ) => (state.nav_menu = nav_menu),
        setcurrentplayer :(state,currentplayer ) => (state.currentplayer = currentplayer),
        setdashdata:(state,dashdata ) => (state.dashdata = dashdata),
        setstatistics :(state,statistics ) => (state.statistics = statistics),
        setsystemsettings :(state,systemsettings ) => (state.systemsettings = systemsettings),
    },


    actions : {

        async clearutils( { commit }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', '')
            commit('setapierror', '')
            commit('seterrors', '')
            commit('setpageloading', '')
        },

        play_Audio_file({ commit }, {audioFile, routename }) {
            let audio = new Audio(audioFile);
            if (this.currentAudio && !this.currentAudio.ended && this.playingAudio === audioFile) {
                if (this.isPaused) {
                    this.currentAudio.play();
                    this.isPaused = false;
                    let data = {
                        audiolink:audioFile,
                        routelink:routename
                    }
                    commit('setcurrentplayer', data)
                } else {
                    this.currentAudio.pause();
                    this.currentAudio.currentTime = 0;
                    this.isPaused = true;
                    commit('setcurrentplayer', '')
                }
            } else {
            if (this.currentAudio) {
                this.currentAudio.pause();
                this.currentAudio.currentTime = 0;
                commit('setcurrentplayer', '')
            }
            let data = {
                audiolink:audioFile,
                routelink:routename
            }
            commit('setcurrentplayer', data)
            this.currentAudio = audio;
            this.playingAudio = audioFile;
            this.currentAudio.play();
            }
        },



        async changenav_menu( { commit }, value) {
            commit('setnav_menu', value)
        },

        async check_for_system_updates({ commit }, data){
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'check_for_system_updates')
            return new Promise((resolve, reject) => {
            axios.post("/user/system/updates/check", data)
            .then(response => {	
                commit('setloader', false)
                
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	

        },


        async get_dash_data({ commit }, data){
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_dash_data')
            return new Promise((resolve, reject) => {
            axios.post("/admin/dashboard/data", data)
            .then(response => {	
                commit('setloader', false)
                commit('setdashdata', response.data.res)
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	

        },

        async get_system_settings({ commit }, data){
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_system_settings')
            return new Promise((resolve, reject) => {
            axios.post("/admin/settings/system", data)
            .then(response => {	
                commit('setloader', false)
                commit('setsystemsettings', response.data.res)
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	

        }        


    },

    
}