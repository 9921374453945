<template>
    <button :class="['btn btn-disabled', className]" v-if="loadingvalue != '' && loadingvalue === loading">
        <span class="flex items-center justify-center">
            <svg stroke="currentColor" fill="none" stroke-width="0" viewBox="0 0 24 24" class="animate-spin mr-1 w-5 h-5" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill="currentColor"></path>
                <path d="M2 12C2 6.47715 6.47715 2 12 2V5C8.13401 5 5 8.13401 5 12H2Z" fill="currentColor"></path>
            </svg>
            {{ loadingTxt }}
        </span>
    </button>

    <button v-else :class="['btn btn-default mr-2', className]" class="btn btn-default mr-2" >
        <span class="flex items-center justify-center">
            <span class="text-lg">
                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" aria-hidden="true" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
                </svg>
            </span>
            <span>{{  btnText }}</span>
            
        </span>
    </button>    
</template>
<script>
import { mapGetters } from 'vuex'
export default {

    props: {
        
        loadingvalue: {
            type: [String, Boolean, Number],
            default: ''
        },
        loadingTxt: {
            type: String,
            default: 'Loading...'
        },
        className: {
            type: String,
            default: 'btn-primary'
        },
        btnText: {
            type: String,
            default: 'Submit'
        }
    },

    computed:{
        ...mapGetters(['loading'])
    },

    methods: {
        handleClick() {
            if(!this.loading) {
            this.onClick()
            }
        }
    },
}
</script>