import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import categories from './modules/categories';
import languages from './modules/languages';
import feedbacks from './modules/feedbacks';
import agents from './modules/agents';
import calls from './modules/calls';
import profiles from './modules/callerprofiles';
import systemupdates from './modules/systemupdates';
import categoryfeedbacks from './modules/categoryfeedbacks';
import systemsettings from './modules/systemsettings';
import callsettings from './modules/callsettings';
import communications from './modules/communications';
export default createStore({
  modules: {
    auth, 
    utils,
    categories,
    languages,
    feedbacks,
    agents,
    calls,
    profiles,
    systemupdates,
    categoryfeedbacks,
    systemsettings,
    callsettings,
    communications
  },
    
  plugins: [createPersistedState(
    {
      key : 'smarttraderuganda.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
