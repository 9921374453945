import store from '@/store'

const adminguard = (to, from, next) => {
    if(localStorage.getItem('callcenterauthtoken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.name ){
        return next()
    }else{
        return next({ name: 'login' })
    }
};


let routes = [
    { 
        path: "/",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/main.vue"),
        title:'',

          children: [

            {
              path: "/",
              name: "welcome",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/dash.vue"),
              meta: {
                requiresAuth: true,
              title: 'Admin Dashboard'
              }
            },

            {
              path: "/analytics",
              name: "analytics",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/analytics/main.vue"),
              meta: {
                requiresAuth: true,
              title: 'Admin Dashboard'
              }
            },

            //active calls
            {
              path: "/active-calls",
              name: "active_calls",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/active_calls.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Agents'
              }
            },

            //agents
            {
              path: "/agents",
              name: "app_agents",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/agents/agents.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Agents'
              }
            },
            {
              path: "/agents/:id",
              name: "app_agent_details",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/agents/agents_details.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Agents'
              }
            },

            // /languages
            {
              path: "/languages",
              name: "app_languages",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/languages/languages.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Languanges'
              }
            },
            {
              path: "/languages/:id", 
              name: "app_language_details",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/languages/language_details.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Languange Details'
              }
            },    
            
            //categories
            {
              path: "/categories",
              name: "app_categories",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/categories/categories.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Categories'
              }
            },

            {
              path: "/categories/:id",
              name: "categories_details",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/categories/category_details.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Categories'
              }
            },



            {
              path: "/languages/:id",
              name: "app_language_details",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/languages/language_details.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Languange Details'
              }
            },  

            //calls
            //profiles
            {
              path: "/call-profiles",
              name: "call_profiles",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/calls/call_profiles.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Call Profiles'
              }
            },
            {
              path: "/call-profiles/:id",
              name: "call_profile_details",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/calls/call_profile_details.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Call Profile Details'
              }
            },

            //call_logs
            {
              path: "/call-logs",
              name: "call_logs",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/calls/call_logs.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Call Logs'
              }
            },
            {
              path: "/call-logs/:id",
              name: "call_log_details",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/calls/call_log_details.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Call Log Details'
              }
            },

            {
              path: "/feedbacks",
              name: "feedbacks",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/calls/feedbacks.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Feedbacks'
              }
            },
        

            //communications
            {
              path: "/communications",
              name: "app_communications",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/communication/communications.vue"),
              meta: {
                requiresAuth: true,
              title: 'Manage Mass Communications'
              }
            },

            //settings
            {
              path: "/settings",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/settings/main.vue"),
              children:[
                {
                  path: "profile",
                  name: "user_profile",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/profile.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Mass Communications'
                  }
                },

                {
                  path: "calls",
                  name: "call_settings",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/call_settings.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Mass Communications'
                  }
                },

                {
                  path: "system",
                  name: "system_settings",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/system_settings.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Mass Communications'
                  }
                },

                {
                  path: "updates",
                  name: "system_updates",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/settings/system_updates.vue"),
                  meta: {
                    requiresAuth: true,
                  title: 'Manage Mass Communications'
                  }
                },

              ]
             
            },



            
        ]
    }
]
export default routes;