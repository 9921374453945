export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast() 

export default {
    state : {
        feedbacks :'', 
        feedback :'', 
    },
    
    getters : {
        feedbacks: (state )=> state.feedbacks,
        feedback: (state )=> state.feedback,
    },


    mutations : { 
        setfeedbacks :(state,feedbacks ) => (state.feedbacks = feedbacks), 
        setfeedback :(state,feedback ) => (state.feedback = feedback), 
    },
 
    actions : {

        async create_feedback( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_feedback')
            return new Promise((resolve, reject) => {
            axios.post("/admin/feedbacks/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfeedbacks', response.data.res)
                toast.success("Feedback Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_feedbacks( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_feedbacks')
            return new Promise((resolve, reject) => {
            axios.post("/admin/feedbacks?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfeedbacks', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_feedback( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_feedback' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/feedbacks/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfeedback', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_feedback( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_feedback' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/feedbacks/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfeedbacks', response.data.res)
                toast.success("Feedback Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async change_feedback_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_feedback_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/admin/feedbacks/status/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setfeedbacks', response.data.res)
                        if (response.data.res.data[0].status == 1) {
                            toast.success("Feedback Activated Successfully")
                        } else {
                            toast.success("Feedback Suspended Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_feedback( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_feedback' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/feedbacks/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setfeedbacks', response.data.res)
                toast.success("Feedback Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}